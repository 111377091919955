import React from 'react';
import './App.css';

function App() {
  return (
    <>
      <header>
        <div className="container">
          <div className="hero">
            <img src="mugshot_2023.png" alt="Zack Richardson" className="profile-img" />
            <div className="hero-content">
              <h1>Zack Richardson</h1>
              <p className="professional-summary">
                Pragmatic Software Engineer with over 6 years of experience developing high-scale services, observability tooling, and infrastructure automation. Skilled in Azure, .NET, Kubernetes, and React. Currently leveraging AI tools to enhance developer productivity and improve service reliability.
              </p>
              <div className="social-links">
                <a href="https://www.linkedin.com/in/richardsonz/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                  LinkedIn
                </a>
                <a href="https://github.com/ZRich97">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                  </svg>
                  GitHub
                </a>
                <a href="https://drive.google.com/file/d/10ESQx1BMwhtk5Np_HGtnoaHTARkn-eRl/view?usp=sharing">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                  </svg>
                  Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="experience">
        <div className="container">
          <h2>Experience</h2>
          
          {/* Microsoft - Software Engineer II */}
          <div className="experience-item">
            <div className="experience-header">
              <img src="MSFT.jpg" alt="Microsoft Logo" className="company-logo" />
              <div className="experience-title">
                <h3>Microsoft</h3>
                <p>May 2022 – Present | Redmond, WA</p>
                <h4>Software Engineer II</h4>
              </div>
            </div>
            <div className="experience-details">
              <ul>
                <li>Leveraging LLM tools to create and update troubleshooting documentation, enhancing TTx metrics and automating manual mitigation tasks</li>
                <li>Built OpenTelemetry-based observability libraries to unify metrics and traces across multiple service groups, increasing system visibility and accelerating issue resolution</li>
                <li>Developed IaC library for Azure services, enabling rapid and reliable deployments across hundreds of Kubernetes clusters worldwide</li>
              </ul>
            </div>
          </div>

          {/* Microsoft - Software Engineer I */}
          <div className="experience-item">
            <div className="experience-header">
              <img src="MSFT.jpg" alt="Microsoft Logo" className="company-logo" />
              <div className="experience-title">
                <h3>Microsoft</h3>
                <p>Mar 2020 – Apr 2022 | Redmond, WA</p>
                <h4>Software Engineer I</h4>
              </div>
            </div>
            <div className="experience-details">
              <ul>
                <li>Developed service and infrastructure monitoring solutions utilizing Grafana, Prometheus, and Kusto, standardizing observability systems across services</li>
                <li>Built and maintained .NET libraries consumed by dozens of services, including a CosmosDB client and authentication middleware</li>
                <li>Migrated key services (serving 100k+ RPS) onto Azure Kubernetes Service (AKS) and .NET Core</li>
              </ul>
            </div>
          </div>

          {/* Expedock */}
          <div className="experience-item">
            <div className="experience-header">
              <img src="EXPEDOCK.jpg" alt="Expedock Logo" className="company-logo" />
              <div className="experience-title">
                <h3>Expedock</h3>
                <p>Oct 2019 – Feb 2020 | Remote</p>
                <h4>DevOps Engineer</h4>
              </div>
            </div>
            <div className="experience-details">
              <ul>
                <li>Containerized backend services and stood up initial AWS infrastructure, enabling faster deployments and improving system reliability</li>
              </ul>
            </div>
          </div>

          {/* Intuit */}
          <div className="experience-item">
            <div className="experience-header">
              <img src="INTUIT.jpg" alt="Intuit Logo" className="company-logo" />
              <div className="experience-title">
                <h3>Intuit</h3>
                <p>Jun 2019 – Sep 2019 | Mountain View, CA</p>
                <h4>DevOps SWE Intern</h4>
              </div>
            </div>
            <div className="experience-details">
              <ul>
                <li>Developed AWS-hosted Jenkins server decreasing time-to-deploy by 60%</li>
              </ul>
            </div>
          </div>

          {/* JT4 LLC */}
          <div className="experience-item">
            <div className="experience-header">
              <img src="JT4.jpg" alt="JT4 Logo" className="company-logo" />
              <div className="experience-title">
                <h3>JT4 LLC</h3>
                <p>Jun 2017 – Feb 2019 | Las Vegas, NV</p>
                <h4>Field Engineer I</h4>
              </div>
            </div>
            <div className="experience-details">
              <ul>
                <li>Developed command-and-control system using Java, Swing, and the NASA WorldWind SDK</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="education">
        <div className="container">
          <h2>Education</h2>
          <div className="education-item">
            <img src="CALPOLY.jpg" alt="Cal Poly Logo" className="school-logo" />
            <div className="education-details">
              <h3>California Polytechnic State University - San Luis Obispo</h3>
              <p>B.S. Software Engineering, 2015–2019</p>
              <p>
                <strong>Senior Project:</strong> Developed iOS app using Swift, AWS, and Intuit’s CardParts framework to track health goals, workouts, and nutrition.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" className="projects-section">
        <div className="container">
          <h2>Projects</h2>
          <div className="project-cards">
            <div className="project-card">
              <img src="FITTRAK.png" alt="Fitness Tracker App" />
              <div className="project-card-content">
                <h3>Fitness Tracker iOS App</h3>
                <p>
                  Undergrad senior project – a fitness tracking app built with Swift, integrating AWS services for authentication and storage.
                </p>
                <a href="https://drive.google.com/file/d/1EM14f5058jc36MxKPYonL--PVW6cxf7i/view?usp=drive_link" className="cta-button">View Demo</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
